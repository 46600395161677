import {
  Button,
  CharacterCount,
  Checkboxes,
  ErrorSummary,
  FileUpload,
  Header,
  NotificationBanner,
  Radios,
  createAll,
} from 'govuk-frontend'

import { CookiePreferences } from './components/cookie-preferences.mjs'
import { init as initAppSkipLinks } from './components/skip-links.mjs'

/**
 * Frontend application
 * @param {ApplicationWindow} context
 */
export const application = async (context) => {
  const { config } = context.GOVUK

  // Application customised elements
  const $cookieBanner = document.querySelector('[data-module="govuk-cookie-banner"]')
  const $skipLinks = document.querySelectorAll('[data-module="govuk-skip-link"]')
  const $accordions = document.querySelectorAll('[data-module="govuk-accordion"]')
  const $autocompletes = document.querySelectorAll('[data-module="accessible-autocomplete"]')

  // custom timeout dialog
  const $timeoutDialog = document.getElementById('timeout-dialog')

  // recaptcha
  const $recaptcha = document.getElementById('recaptcha')

  // Sticky navigation
  const $highlightActiveSectionHeading = document.querySelectorAll('[data-module="highlight-active-section-heading"]')

  // Landmarks
  createAll(Header)

  // Form elements
  createAll(CharacterCount)
  createAll(Checkboxes)
  createAll(Radios)
  createAll(Button, { preventDoubleClick: true })
  createAll(ErrorSummary)
  createAll(FileUpload)

  // Banners
  createAll(NotificationBanner)

  // Initialise skip links
  initAppSkipLinks($skipLinks)

  // Lazy load accordions
  if ($accordions.length) {
    import(/* webpackChunkName: 'components/accordions' */ './components/accordions.mjs')
      .then(({ init }) => init($accordions))
      // eslint-disable-next-line no-console
      .catch(console.error)
  }

  // Lazy load autocompletes
  if ($autocompletes.length) {
    import(/* webpackChunkName: 'components/autocompletes' */ './components/autocompletes.mjs')
      .then(({ init }) => init($autocompletes))
      // eslint-disable-next-line no-console
      .catch(console.error)
  }

  if ($timeoutDialog) {
    import(/* webpackChunkName: 'components/timeout-dialog' */ './components/timeout-dialog.mjs')
      .then(({ init }) => init($timeoutDialog))
      // eslint-disable-next-line no-console
      .catch(console.error)
  }

  if ($recaptcha) {
    import(/* webpackChunkName: 'components/recaptcha' */ './components/recaptcha.mjs')
      .then(({ init }) => init($recaptcha))
      // eslint-disable-next-line no-console
      .catch(console.error)
  }

  if ($highlightActiveSectionHeading.length) {
    import(
      /* webpackChunkName: 'components/highlight-active-section-heading' */ './components/highlight-active-section-heading.mjs'
    )
      .then(({ init }) => init($highlightActiveSectionHeading))
      // eslint-disable-next-line no-console
      .catch(console.error)
  }

  // Google Analytics event queue
  context.dataLayer = context.dataLayer ?? []

  function gtag() {
    context.dataLayer.push(arguments)
  }

  // Cookie preferences
  const preferences = new CookiePreferences(config.cookieBanner.name, ({ usage }) => {
    const measurementId = config.googleAnalytics?.measurementId

    // Analytics cookies opt-in
    if (usage && measurementId) {
      const script = document.createElement('script')

      script.async = true
      script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`

      // Configure account
      gtag('js', new Date())
      gtag('config', measurementId)

      // Add Google Analtyics
      document.body.appendChild(script)
    }
  })

  // Lazy load cookie banner
  if ($cookieBanner && $cookieBanner instanceof HTMLElement) {
    import(/* webpackChunkName: 'components/cookie-messages' */ './components/cookie-messages.mjs')
      .then(({ CookieMessages }) => new CookieMessages($cookieBanner, preferences).init())
      // eslint-disable-next-line no-console
      .catch(console.error)
  }
}

// Start application
application(window)
  // eslint-disable-next-line no-console
  .catch(console.error)

/**
 * Frontend config
 * @typedef {object} TrackingOptions
 * @property {{ name: string; cookie: object }} cookieBanner - Cookie banner options
 * @property {{ measurementId?: string; }} googleAnalytics - Google Analytics options
 */

/**
 * Application global context
 * @typedef {object} ApplicationContext
 * @property {IArguments[]} [dataLayer] - Google Analytics event queue
 * @property {{ config: TrackingOptions }} [GOVUK] - GOV.UK application globals
 */

/**
 * Application window extended
 * @typedef {ApplicationContext & Window & globalThis} ApplicationWindow
 */
